@import "/var/www/devk/project/devk/web/src/theme/_variables.scss";@import "/var/www/devk/project/devk/web/src/dashboard/scss/dashboard.scss";
hr {
  border: 0.8px solid #E4E8ED !important;
  margin: 0 !important;
}

.text-grey {
  color: #747c88;
}

.text-green {
  color: #4dc273 !important;
}

.text-orange {
  color: #ef9001 !important;
}

.text-steel {
  color: #3c4858;
}

.text-disabled {
  color: #999;
}

.text-error {
  color: #ff5739;
}

.text-thin {
  font-weight: 300;
}

.ptxs {
  padding-top: 7px;
}

.capitalize {
  text-transform: capitalize;
}

.pts {
  padding-top: 14px;
}

.ptm {
  padding-top: 24px;
}

.pam {
  padding: 24px;
}

.pxm {
  padding-left: 24px;
  padding-right: 24px;
}

.ptl {
  padding-top: 35px;
}

.pbxs {
  padding-bottom: 10px;
}

.pbs {
  padding-bottom: 14px;
}

.pbm {
  padding-bottom: 24px;
}

.pbl {
  padding-bottom: 35px;
}

.prs {
  padding-right: 16px;
}

.prm {
  padding-right: 24px;
}

.prl {
  padding-right: 35px;
}

.plxs {
  padding-left: 7px;
}

.pls {
  padding-left: 20px;
}

.plm {
  padding-left: 44px;
}

.mbs {
  margin-bottom: 14px;
}

.mam {
  margin: 24px;
}

.mb-0 {
  margin-bottom: 0;
}

.mbm {
  margin-bottom: 24px;
}

.mym {
  margin-bottom: 24px;
  margin-top: 24px;
}

.mbl {
  margin-bottom: 35px;
}

.grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.grid-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

.flex {
  display: flex;
}

.flex-align-center {
  display: flex;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pointer {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
}

.mobile {
  display: none;
}

.hidden {
  opacity: 0;
}

@media screen and (max-width: 480px) {
  .mobile-screen-centered {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 900px) {
  .grid-2 {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0;
  }

  .grid-3 {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .mobile {
    display: flex;
  }

  .desktop {
    display: none;
  }
}
